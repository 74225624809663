import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Container from '~components/Container';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import RichText from '~components/RichText';
import ShowcaseBgImage from '~images/spotlight_showcase_bg.png';
import MobileApp1 from '~images/app/mobile_app_spotlight_showcase_1.png';
import MobileApp2 from '~images/app/mobile_app_spotlight_showcase_2.png';
import VideoPlayCard from '~components/VideoPlayCard';
import { formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import { extractSpotlight } from '~utils/unfurl-helpers';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getSubscribeButtonProps } from '~utils/buttontext';
import useConversionFramework from '~hooks/useConversionFramework';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import CategoryButtons from './CategoryButtons';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
  },
  contentContainer: {
    height: '100%'
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  heroTitle: {
    width: '100%',
    textAlign: 'center',
    color: '#01153D',
    fontSize: 64,
    fontFamily: 'Inter',
    fontWeight: '500',
    marginTop: '70px',
    marginBottom: '43px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      marginTop: '15px',
      marginBottom: '20px'
    }
  },
  heroImage: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: props => (props.hasMobileImage ? 'fit-content' : '100%')
    }
  },
  heroDescription: {
    maxWidth: 420,
    textAlign: 'center',
    '& > *': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 400,
      color: '#000000',
      lineHeight: '128%',
      margin: '0px 0px 43px',
      [theme.breakpoints.down('xs')]: {
        margin: '0px 0px 38px'
      }
    },
    '& > p:empty': {
      height: '14px'
    }
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  spotlightsContainer: {
    position: 'relative',
    width: '100%',
    minHeight: 200,
    borderRadius: 11,
    backgroundImage: `url(${ShowcaseBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover',
    marginBottom: '52px',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      marginBottom: '0px'
    }
  },
  tabContainer: {
    position: 'absolute',
    top: '-18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 0
    }
  },
  spotlightArea: {
    maxWidth: '865px',
    padding: '125px 10px 100px 10px',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0px 33px 0px'
    }
  },
  leftMobile: {
    position: 'absolute',
    left: 0,
    top: '55%',
    transform: 'translate(-8%, -50%)',
    maxWidth: '489px',
    zIndex: 10,
    [theme.breakpoints.down(1500)]: {
      width: '300px'
    },
    [theme.breakpoints.down(1300)]: {
      width: '240px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  rightMobile: {
    position: 'absolute',
    right: 0,
    top: '55%',
    maxWidth: '489px',
    transform: 'translate(8%, -50%)',
    zIndex: 10,
    [theme.breakpoints.down(1500)]: {
      width: '300px'
    },
    [theme.breakpoints.down(1300)]: {
      width: '240px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const PreviewSpotlights = props => {
  const { section_identifier, title, description, tabs, bottom_shape_divider } = props;
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;

  const {
    text: subscribeBtnText,
    url: subscribeBtnUrl,
    shouldTrackAnalytics: shouldCtaButtonTrackAnalytics,
    analyticsEvent: ctaAnalyticsEvent
  } = getSubscribeButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  const selectedTabSpotlight = useMemo(() => {
    const selectedTab = tabs[selectedIndex];
    return extractSpotlight(selectedTab?.spotlight_playlist);
  }, [tabs, selectedIndex]);

  if (!IS_FREE_TRIAL_ENABLED) {
    return null;
  }

  return (
    <div className={classes.container} id={section_identifier}>
      <img className={classes.leftMobile} src={MobileApp1} alt="AB app" />
      <img className={classes.rightMobile} src={MobileApp2} alt="AB app" />
      <Container className={classes.contentContainer}>
        <div className={classes.heroContainer}>
          <h1 className={classes.heroTitle}>{title}</h1>
          <RichText
            html={description && description.html}
            externalClassName={classes.heroDescription}
            verticalSpacing={0}
          />
          <div className={classes.spotlightsContainer}>
            <div className={classes.tabContainer}>
              <CategoryButtons
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeCategory={setSelectedIndex}
              />
            </div>
            <div className={classes.spotlightArea}>
              <VideoPlayCard
                title={selectedTabSpotlight?.playlist_title}
                url={selectedTabSpotlight?.url}
                description={selectedTabSpotlight?.playlist_description}
                image={selectedTabSpotlight?.cover_image}
                author={selectedTabSpotlight?.author}
                videoCount={selectedTabSpotlight?.total_videos}
                isAudio={selectedTabSpotlight?.is_audio}
                duration={formatDurationOfFirstVideoTishaBav(
                  selectedTabSpotlight?.first_video_duration
                )}
              />
            </div>
          </div>
          <div className={classes.buttonArea}>
            <RoundedLinkButton
              text={subscribeBtnText}
              to={subscribeBtnUrl}
              size="small"
              variant="blue"
              shouldTrackAnalytics={shouldCtaButtonTrackAnalytics}
              analyticsEvent={ctaAnalyticsEvent}
              analyticsTitle={title}
              analyticsSection="Preview Spotlights"
            />
          </div>
        </div>
      </Container>

      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

PreviewSpotlights.propTypes = {
  section_identifier: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab_title: PropTypes.string,
      spotlight_playlist: PropTypes.shape({})
    })
  ),
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

PreviewSpotlights.defaultProps = {
  section_identifier: '',
  title: '',
  description: '',
  tabs: [],
  bottom_shape_divider: null
};

export default PreviewSpotlights;
