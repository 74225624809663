import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap'
  },
  categoryButton: {
    fontSize: 12,
    color: '#FFFFFF',
    padding: '8px 16px 8px 16px',
    backgroundColor: '#F8A39B',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FF4D3C'
    },
    '&:active': {
      backgroundColor: '#c33325'
    }
  },
  categoryButtonActive: {
    fontWeight: 700,
    backgroundColor: '#FF4D3C'
  }
});

const CategoryButtons = ({ tabs, onChangeCategory, selectedIndex }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {tabs.map((item, index) => {
        const active = selectedIndex === index;
        return (
          <Button
            key={item.tab_title}
            variant="text"
            className={`${classes.categoryButton} ${active && classes.categoryButtonActive}`}
            onClick={() => onChangeCategory(index)}
            disableRipple
          >
            {item.tab_title}
          </Button>
        );
      })}
    </div>
  );
};

CategoryButtons.propTypes = {
  onChangeCategory: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab_title: PropTypes.string
    })
  )
};

CategoryButtons.defaultProps = {
  tabs: []
};

export default CategoryButtons;
